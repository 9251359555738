import React from 'react'
import Link from 'gatsby-link'

import './style.scss'
import Layout from 'layouts/Page'
import SEO from 'components/SEO/SEO'

const HowItWorksPage = () => (
  <div id="hiw-page">
    <div className="content-wrap">
      <section className="how-it-works page-title">
        <div className="container">
          <h2>How it works</h2>

          <div className="how-it-works-section row">
            <h1>For borrowers</h1>
            <div className="col-md-4 hiw-col">
              <h3>Browse & Book</h3>
              <p>
                Discover items you need from bikes to boosted boards - pickup is
                local and on your time.
              </p>
            </div>
            <div className="col-md-4 hiw-col">
              <h3>Play & Pay</h3>
              <p>
                Have fun with your rental for as long as you need. Secure
                payments are done in one easy click.
              </p>
            </div>
            <div className="col-md-4 hiw-col">
              <h3>Return & Review</h3>
              <p>
                After you're done, dropoff the item with the lender and give a
                review of your experience.
              </p>
            </div>
          </div>
          <div className="btn-center">
            <Link to="/search">
              <button className="cta-btn">Browse items</button>
            </Link>
          </div>

          <div className="how-it-works-section row">
            <h1>For lenders</h1>
            <div className="col-md-4 hiw-col">
              <h3>List & Accept</h3>
              <p>
                Post your items on Mellow for free to reach the community.
                Accept rental requests from community members
              </p>
            </div>
            <div className="col-md-4 hiw-col">
              <h3>Lend</h3>
              <p>
                Schedule a convenient meetup time for the borrower to pickup the
                item.
              </p>
            </div>
            <div className="col-md-4 hiw-col">
              <h3>Get Paid</h3>
              <p>
                After the rental is complete recieve your earnings through our
                secure payment system.
              </p>
            </div>
          </div>

          <div className="btn-center">
            <Link to="/submit">
              <button className="cta-btn">List an item</button>
            </Link>
          </div>
          <div style={{ postion: 'relative' }}>
            <svg
              className="how-it-works-cloud"
              xmlns="http://www.w3.org/2000/svg"
              width="1360"
              height="958"
              viewBox="0 0 1360 958"
            >
              <path
                fill="#DEE1EA"
                fillRule="evenodd"
                d="M856.032,43.233 C763.56,51.354 755.995,47.037 702.004,24.653 C665.707,8.809 625.635,0 583.5,0 C457.462,0 349.808,78.649 306.897,189.541 C272.98,271.849 260.919,283.503 181.846,316.876 C75.359,358.829 0,462.613 0,584 C0,742.506 128.494,871 287,871 C328.535,871 368.001,862.164 403.643,846.289 C491.057,809.571 522.617,802.872 651.485,883.891 C723.395,930.758 809.265,958 901.5,958 C1154.723,958 1360,752.723 1360,499.5 C1360,246.277 1154.723,41 901.5,41 C886.155,41 870.989,41.76 856.032,43.233 Z"
                opacity=".2"
              />
            </svg>
          </div>
          <div className="how-it-works-section row">
            <h1>Our awesome community</h1>
            <div className="col-md-4 hiw-col">
              <h3>Verification</h3>
              <p>
                Renters and Lenders are vetted through our ID verification
                process to create a trusted community.
              </p>
            </div>
            <div className="col-md-4 hiw-col">
              <h3>Reviews</h3>
              <p>
                Approvals and review are an easy way to learn from others and
                share your experience.
              </p>
            </div>
            <div className="col-md-4 hiw-col">
              <h3>Meetups</h3>
              <p>
                Look for opportunities to meet and hang out with local members
                in person at sponsored meetups.
              </p>
            </div>
          </div>
          <div className="btn-center">
            <p className="hint-text above-btn">Still have questions?</p>
            <Link to="/contact">
              <button className="cta-btn">Contact us</button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  </div>
)

export default ({ location }) => (
  <Layout navbar="main" location={location}>
    <SEO title="How it works" />
    <HowItWorksPage />
  </Layout>
)
